import React, {useState} from 'react';
import axios from 'axios';
import TgUserAbsent from "./TgUserAbsent";
import SmsMessage from "./SmsMessage";
import PhoneWaiting from "./PhoneWaiting";
import CodeWaiting from "./CodeWaiting";
import Error from "./Error";
import ProviderType from "./ProviderType";
import SubmitButton from "./SubmitButton";
import BackButton from "./BackButton";
import SendCodeAgain from "./SendCodeAgain";
import Email from "./Email";
import CodeNotResponse from "./CodeNotResponse";
import EmailRegister from "./EmailRegister";
import ReuseCode from "./ReuseCode";
import AuthSuccess from "./AuthSuccess";
import PropTypes from "prop-types";

CodeForm.propTypes = {
    step: PropTypes.string,
    setStep: PropTypes.func,
    phone: PropTypes.string,
    onEndTimer: PropTypes.func,
    onValidate: PropTypes.func,
}
function CodeForm(props) {
    const setFormVisible = () => {
        props.setPhoneAuth(false)
        props.setEmailAuth(true)
    }

    const onSendGetCode = (e, reuseMethod=false) => {
        e.preventDefault();
        let data;

        if (props.onValidate) {
            const curError = props.onValidate()
            if (curError) {
                props.setErrors([curError])
                return
            }
        }
        if (props.rootForm.current) {
            data = Object.fromEntries(new FormData(props.rootForm.current.querySelector("#ctweb-smsauth_form_phone")));
            data.is_ajax_post = 'Y'
            data.PROVIDER_TYPE = props.type
            data.PHONE = data.PHONE.replace(/\D/g, "").substr(data.__phone_prefix.length)

            if (reuseMethod) {
                data.method = 'REUSE_CODE'
            }
            if (props.isRegister) {
                let data_register = Object.fromEntries(new FormData(document.querySelector("#bx_2930803142__form")));
                data = {...data, ...data_register}
            }

            if (window.recaptcha !== undefined) {
                data.recaptcha_token = window.recaptcha.getToken()
            }
        }
        if (data.PHONE) {
            props.setLoaded(true)

            axios.post('/local/components/ctweb/sms.authorize/ajax.php', data,
                {
                    headers: {
                        "Content-Type": "application/x-www-form-urlencoded",
                    }
                }
            ).then(response => {
                props.setLoaded(false)
                props.setStep(response.data.STEP);

                if (response.data.ERRORS.length > 0) {
                    props.setErrors(response.data.ERRORS);
                } else {
                    props.setErrors([])
                }
            })
        }

    }

    const onChangePhone = (e) => {
        e.preventDefault();

        let data;
        if (props.rootForm.current) {
            data = Object.fromEntries(new FormData(props.rootForm.current.querySelector("#ctweb-smsauth_form_phone")));
            data.is_ajax_post = 'Y'
            data.PROVIDER_TYPE = props.type
            data.method = 'CHANGE_PHONE'
            data.PHONE = data.PHONE.replace(/\D/g, "").substr(data.__phone_prefix.length)
        }

        axios.post('/local/components/ctweb/sms.authorize/ajax.php', data,
            {
                headers: {
                    "Content-Type": "application/x-www-form-urlencoded",
                }
            }
        ).then(response => {
            if (response.data.ERRORS.length > 0) {
                props.setErrors(response.data.ERRORS);
            } else {
                props.setErrors([])
            }
            props.setStep(response.data.STEP);
        })
    }

    return (
        <form id="ctweb-smsauth_form_phone"
              className={" flex flex-col "  + (props.isRegister ? "" : " items-center ")
                  + (props.phoneAuth ? "flex" : " hidden")}
              method="POST" name="auth">
            <input type="hidden" name="sessid"
                   value={BX.message("bitrix_sessid")}/>
            <input type="hidden" name="REGISTER" value=""/>
            <input type="hidden" name="PROFILE_AUTH"
                   value="N"/>
            <input type="hidden" name="recaptcha_token" value=""/>

            <PhoneWaiting phone={props.phone} rootForm={props.rootForm} step={props.step} isRegister={props.isRegister}/>

            <ProviderType type={props.type} setType={props.setType} setStep={props.setStep} tgEnabled={props.tgEnabled}
                          errors={props.errors}/>

            <AuthSuccess step={props.step}/>
            <TgUserAbsent step={props.step} setStep={props.setStep} onChangePhone={onChangePhone} tgName={props.tgName}
                          tgUrl={props.tgUrl} />
            <SmsMessage step={props.step} type={props.type} />

            <CodeWaiting onEndTimer={props.onEndTimer}  step={props.step} setStep={props.setStep}
                         type={props.type} setErrors={props.setErrors} rootForm={props.rootForm}
                         onChangePhone={onChangePhone} reuseTime={props.reuseTime} setReuseTime={props.setReuseTime}
                         isRegister={props.isRegister}
                         />

            <Error errors={props.errors} setErrors={props.setErrors} step={props.step} setStep={props.setStep}/>

            <SubmitButton step={props.step} loaded={props.loaded} errors={props.errors}
                          isRegister={props.isRegister} onSendGetCode={onSendGetCode}
            />
            <div className="flex flex-col w-full">
                <BackButton/>
                <ReuseCode step={props.step} setStep={props.setStep} onSendGetCode={onSendGetCode} isRegister={props.isRegister}/>
                <SendCodeAgain step={props.step} setStep={props.setStep} onSendGetCode={onSendGetCode} errors={props.errors}/>

                <Email setFormVisible={setFormVisible} isRegister={props.isRegister}/>
                <CodeNotResponse step={props.step} setStep={props.setStep}/>
                <EmailRegister isRegister={props.isRegister}/>
            </div>

        </form>
    );
}

export default CodeForm;